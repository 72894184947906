<template>
  <div>
    <loader :loading="loading"/>

    <page-header :title="`Clientes`">
      <template v-slot:breadcrumbs>
        <li>Clientes</li>
      </template>

      <template v-slot:actions>
        <div>
          <button class="btn btn-success" @click.prevent="$router.push('/clientes/add')">Novo Cliente</button>
        </div>
      </template>
    </page-header>

    <filters @search="handleSearch(filters, 1)" @clear="clearFilters">
      <form @keyup.enter="handleSearch(filters)">
        <div class="row g-2 mt-2">
          <div class="col-1">
            <div>
              <label class="form-label">Código</label>
              <input type="text" class="form-control form-control-sm" v-model="filters.external_id">
            </div>
          </div>

          <div class="col-4">
            <div>
              <label class="form-label">Nome</label>
              <input type="text" class="form-control form-control-sm" v-model="filters.name">
            </div>
          </div>

          <div class="col-2">
            <label class="form-label">Situação</label>
            <select class="form-select form-select-sm" v-model="filters.status">
              <option value="">Todos</option>
              <option value="1">Ativos</option>
              <option value="4">Trial</option>
              <option value="5">Trial Expirado</option>
              <option value="3">Bloqueados</option>
              <option value="2">Inativos</option>
            </select>
          </div>
        </div>
      </form>
    </filters>

    <div class="card bg-white mt-4">
      <div class="card-body p-4">
        <div class="d-flex align-items-center justify-content-end">
          <span class="small text-secondary" v-if="collection.data.length">
            Mostrando {{ collection.data.length }} de {{ collection.meta.total }} registros
          </span>
        </div>
        <div class="table-responsive mt-3">

          <table class="table table-striped" v-if="collection.data.length">
            <thead>
            <tr>
              <th class="text-center">Código</th>
              <th>Nome</th>
              <th class="text-center">Situação</th>
              <th class="text-center">Criado em</th>
              <th class="text-center">Baixado em</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="company in collection.data" :key="company.id">
              <td class="text-center">{{ company.external_id }}</td>
              <td>{{ company.name }}</td>
              <td class="text-center">
                <span class="badge bg-danger" v-if="company.status === 2">Inativo</span>
                <span class="badge bg-success" v-if="company.status === 1">Ativo</span>
                <span class="badge bg-warning" v-if="company.status === 3">Bloqueado</span>
                <span class="badge bg-info" v-if="company.status === 4">Trial</span>
                <span class="badge bg-danger" v-if="company.status === 5">Trial Expirado</span>
              </td>
              <td class="text-center">{{ company.created_at }}</td>
              <td class="text-center">{{ company.inactive_at }}</td>
              <th>
                <div class="d-flex align-items-center justify-content-end">
                  <div class="btn-group">
                    <button type="button" class="btn btn-primary btn-sm dropdown-toggle"
                            data-bs-popper-config='{"strategy":"fixed"}' data-bs-toggle="dropdown">
                      Ações
                    </button>
                    <ul class="dropdown-menu">
                      <li><a class="dropdown-item" href="#" @click.prevent="$router.push(`/clientes/${company.id}`)">Detalhes</a></li>
                      <li><a class="dropdown-item" href="#" @click.prevent="$router.push(`/clientes/edit/${company.id}`)">Editar</a></li>
                    </ul>
                  </div>
                </div>
              </th>
            </tr>
            </tbody>
          </table>

          <div class="bg-light p-2 rounded-2 text-center" v-else>Nenhum registro encontrado...</div>

          <div class="d-flex align-items-center justify-content-center mt-4">
            <paginator
                :current-page="collection.meta.current_page"
                :limit-pages="5"
                :pages="collection.meta.last_page"
                @page-changed="handleSearch(filters, $event)"
                v-if="collection.data.length"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapActions, mapState} from "vuex";
import PageHeader from "@/components/PageHeader.vue";
import Paginator from "@/components/shared/paginator.vue";
import Loader from "@/components/shared/loader.vue";
import Filters from "@/components/shared/Filters.vue";
import axios from "axios";

export default {
  name: 'clients',
  props: {},
  components: {
    Filters,
    Loader,
    Paginator,
    PageHeader
  },
  data() {
    return {
      loading: false,
      filters: {
        external_id: null,
        name: null,
        status: 1
      }
    }
  },
  async mounted() {
    await this.handleSearch(this.filters, this.$route.query.page ?? 1);
  },
  methods: {
    ...mapActions('Clients', ['getAll']),
    async handleSearch(filters, page = 1) {
      this.loading = true;
      const params = {
        page: page,
        ...filters
      }

      await this.getAll(params).finally(() => {
        this.loading = false;
        this.$urlUtils.assignQueryString(this.$route, params);
        this.$scrollTop();
      })
    },
    clearFilters() {
      this.filters = {
        external_id: null,
        name: null,
        status: 1
      }

      this.handleSearch(this.filters, 1);
    }
  },
  computed: {
    ...mapState('Clients', ['collection'])
  }
}
</script>
<style lang="scss" scoped>

</style>
